import './Resource.scss';

import React from 'react';

import arrow from 'assets/images/arrow.png';

interface Props {
  link?: string;
  heading: string;
  description: string;
  imageSource?: string;
  alt?: string;
}

export default class Resource extends React.Component<Props> {
  render() {
    const { link, heading, description, imageSource, alt } = this.props;

    return (
      <a
        className={`Resource ${link != null ? 'Resource--link' : ''}`}
        href={link}
        style={link != null ? { backgroundImage: `url(${arrow})` } : {}}
      >
        {imageSource && (
          <div className="Resource-thumbail">
            <img
              className="Resouce-thumbail-image"
              src={imageSource}
              alt={alt}
            />
          </div>
        )}
        <div>
          <h3 className="Resource-heading">{heading}</h3>
          <p className="Resource-description">
            {description}
            {link == null && (
              <span className="Resource-comingSoon">Coming Soon</span>
            )}
          </p>
        </div>
      </a>
    );
  }
}
