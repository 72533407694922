import './Resources.scss';

import React from 'react';

import Resource from './Resource';
import resourceImageOne from 'assets/images/resource-block_navigating-SEL.jpg';
import resourceImageTwo from 'assets/images/resource-block_words-matter.jpg';
import resourceImageThree from 'assets/images/resource-block_princeton.jpg';
import resourceImageFour from 'assets/images/resource-block_measuring_sel.png';

export default class Resources extends React.Component {
  render() {
    return (
      <>
        <h2>More Resources from the EASEL Lab</h2>
        <div className="Resources">
          <Resource
            link="https://www.wallacefoundation.org/knowledge-center/pages/navigating-social-and-emotional-learning-from-the-inside-out.aspx"
            heading="Looking for programs and strategies?"
            description="Use our guide, Navigating SEL from the Inside Out, to explore
          and compare the different skills, strategies, and features
          offered by 25 leading SEL programs and select a curriculum that
          works best for you."
            imageSource={resourceImageOne}
            alt="Resource document thumbail"
          />
          <Resource
            link="https://easel.gse.harvard.edu/news/what-same-and-what-different"
            heading="Making sense of SEL: Why words matter"
            description="This paper by Stephanie Jones and the EASEL Lab highlights how
          clear and precise language leads to better research and practice
          in the field of SEL."
            imageSource={resourceImageTwo}
            alt="Resource document thumbail"
          />

          <Resource
            link="https://futureofchildren.princeton.edu/news/social-and-emotional-learning"
            heading="Learn about what works in the field of SEL"
            description="This recent issue of the Future of Children, organized and
          edited by Dr. Stephanie Jones of the EASEL Lab, examines the
          latest research on social-emotional development and
          interventions."
            imageSource={resourceImageThree}
            alt="Resource document thumbail"
          />

          <Resource
            link="https://measuringsel.casel.org/wp-content/uploads/2019/02/Frameworks-C.1.pdf"
            heading="What is a framework and why are they important?"
            description="This brief by Stephanie Jones and the EASEL Lab describes what
          frameworks are, how they influence the field, and why it’s
          important to look across them."
            imageSource={resourceImageFour}
            alt="Resource document thumbail"
          />
        </div>
      </>
    );
  }
}
