import './SectionLink.scss';

import React from 'react';
import { Link } from 'gatsby';

import Arrow from './Arrow';

interface Props {
  title: string;
  description: string;
  linkTo: string;
  image: string;
}

const SectionLink: React.FC<Props> = (props) => {
  const { title, description, linkTo, image } = props;

  return (
    <Link className="HomeSectionLink" to={linkTo}>
      <div
        className="HomeSectionLink-image"
        style={{ backgroundImage: `url(${image})` }}
      />

      <h2 className="HomeSectionLink-heading">
        {title} <Arrow color="#ffffff" />
      </h2>

      <p className="HomeSectionLink-description">{description}</p>
    </Link>
  );
};

export default SectionLink;
