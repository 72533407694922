import './index.scss';

import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import { config } from 'config';
import { Framework, Term } from 'types';

import Layout from 'components/layout/Layout';
import Resources from 'components/homepage/Resources';
import FrameworksGrid from 'components/FrameworksGrid';
import {
  CompareDomainsLink,
  CompareFrameworksLink,
  CompareTermsLink
} from 'components/VisualToolLink';

import SectionLink from 'components/home/SectionLink';
import Arrow from 'components/home/Arrow';

import compareFrameworksImage from 'assets/images/visualizations/compare-frameworks.svg';
import developmentalProgressionsImage from 'assets/images/visualizations/developmental-progressions.svg';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
  };
}

const IndexPage: React.FC<Props> = (props) => {
  const { frameworks } = props.data.allFrameworks;
  const { terms } = props.data.allTerms;

  return (
    <Layout>
      <div className="IndexPage">
        {config.stateStandardsEnabled ? (
          <>
            <div className="IndexPage-sectionLinks">
              <SectionLink
                title="Explore Frameworks"
                description="Use the Data Tools to explore the skills that different frameworks target."
                linkTo="/visual-tools/"
                image={compareFrameworksImage}
              />

              <SectionLink
                title="Explore State Standards"
                description="Explore how State Standards focus on social and emotional learning skills."
                linkTo="/state-standards/tools/"
                image={developmentalProgressionsImage}
              />
            </div>

            <div className="IndexPage-stateStandardsIntro">
              <div>
                <h1 className="IndexPage-heading">
                  Navigate the complex field of social and emotional learning.
                </h1>
                <p className="IndexPage-description emphasis">
                  The Explore SEL Domains sit above social and emotional
                  learning frameworks, giving you a common language to discover
                  the skills that they target.
                </p>
              </div>

              <div className="no-print">
                <Link className="IndexPage-learnMoreButton" to="/about/">
                  Learn More about Explore SEL <Arrow color="#246caa" />
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="IndexPage-intro">
              <h1>
                Navigate the complex field of social and emotional learning.
              </h1>
              <p className="emphasis">
                Researchers, educators, employers, and parents agree that social
                and emotional learning (SEL) and non-academic skills matter for
                success in school and life, but it can be difficult to decide
                which skills to focus on – let alone how best to promote and
                measure them. <Link to="/about/">Learn More.</Link>
              </p>
            </div>

            <div className="VisualToolLink-block-wrapper">
              <CompareDomainsLink displayStyle="block" />
              <CompareFrameworksLink displayStyle="block" />
              <CompareTermsLink displayStyle="block" />
            </div>

            <div className="IndexPage-frameworks">
              <h2>Look Inside Frameworks</h2>
              <p>
                Learn more about widely-used non-academic frameworks, and
                compare skills and features
                <br />
                across them.{' '}
                <Link
                  className="IndexPage-frameworks-copy-link"
                  to="/frameworks/"
                >
                  View All
                </Link>
              </p>
              <FrameworksGrid frameworks={frameworks} terms={terms} />
            </div>
          </>
        )}

        <Resources />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        group
        name
        color
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkID
        frameworkName
        parentID
        tier
        definition
      }
    }
  }
`;
