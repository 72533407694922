// import './Arrow.scss';

import React from 'react';

interface Props {
  color?: string;
}

const Arrow: React.FC<Props> = (props) => {
  const { color = '#213153' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={color}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
    </svg>
  );
};

export default Arrow;
